const osm = 'Basemap - Data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>';
const carto = 'Style &copy <a href="https://www.carto.com/">CARTO</a>';
const mapTiler = 'Style &copy <a href="https://www.maptiler.com/">MapTiler</a>';
const opentopomap = 'Style &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>';
const osmCarto = osm + '; ' + carto;
const osmMapTiler = osm + '; ' + mapTiler;

const basemaps: {[key: string]: any} = {
  'carto-light-gray': {
    type: 'xyz',
    url: 'https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
    attribution: osmCarto,
  },
  'carto-light-color': {
    type: 'xyz',
    url: 'https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
    attribution: osmCarto,
  },
  'carto-dark-matter': {
    type: 'xyz',
    url: 'https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
    attribution: osmCarto,
  },
  'bingmaps-roads': {
    type: 'bingmaps',
    key: 'An-zg4U6aGDM1k1CWYarfafpbRVkk7MDWzbhRKuJYyKwNSu4PHND9qEV7G3bMcIi',
    set: 'RoadOnDemand',
  },
  'topomap': {
    type: 'xyz',
    url: 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution: osm + '; ' + opentopomap,
  },
  'mtLight': {
    type: 'xyz',
    url: 'https://api.maptiler.com/maps/basic-v2-light/256/{z}/{x}/{y}.png?key=rLNwIR7CSNLQVNt3NZnI',
    attribution: osmMapTiler,
  },
  'osm': { type: 'xyz', url: 'https://{a-d}.tile.openstreetmap.org/{z}/{x}/{y}.png', attribution: osm },
};

export default basemaps;
