import { Style, Stroke, Fill, Text } from 'ol/style';

export default {
  standard: new Style({
    fill: new Fill({
      color: 'hsla(180, 6.7%, 82.5%,.33)',
      // opacity: .1
    }),
    stroke: new Stroke({
      color: 'hsl(357, 61%, 37%)',
      width: 1,
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    }),
  }),
  dulled: new Style({
    fill: new Fill({
      color: 'hsla(180, 0%, 82.5%,.33)',
      // opacity: .07
    }),
    stroke: new Stroke({
      color: 'hsl(357, 0%, 37%)',
      width: .2,
      // opacity: .7
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    }),
    zIndex: -1,
  }),
  dulledOverlay: new Style({
    fill: new Fill({
      color: 'hsla(180, 0%, 82.5%,.33)',
    //     opacity: .07
    }),
    stroke: new Stroke({
      color: 'hsl(357, 0%, 37%)',
      width: 1,
      // opacity: .7
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    }),
    zIndex: -1,
  }),
  overlay: new Style({
    fill: new Fill({
      color: 'hsla(180, 6.7%, 82.5%,0)',
      // opacity: 0
    }),
    stroke: new Stroke({
      color: 'hsl(357, 61%, 37%)',
      width: 2,
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    }),
  }),
};
