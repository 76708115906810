import { Control } from 'ol/control';

export default class WatermarkControl extends Control {
  constructor(optOptions: any|undefined) {
    const options = optOptions || {};

    const element = document.createElement('div');
    element.className = 'ol-unselectable ol-control map-watermark';

    super({
      element,
      target: options.target,
    });
  }
}
